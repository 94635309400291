.radio-field-combo {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: rem-calc(10);

    .input {
        margin-bottom: 0;
    }

    .radio-container {
        min-width: rem-calc(314);
        margin-right: rem-calc(20);

        &.margin-0 {
            margin: 0;
        }

        .radio.no-checkbox {
            & > label {
                padding-left: 0;

                &:before {
                    content: none;
                }
            }
        }
    }

    .input-container {
        display: flex;
        flex-direction: row;
        -webkit-box-align: center;
        align-items: center;

        .input + .input {
            margin-left: rem-calc(30);
        }

        .additional-info {
            .input__error-bottom {
                bottom: auto;
                top: rem-calc(80);
                left: 0;
            }
        }
    }

    // Special case. Error messages are quite long.
    // This should look alright.
    .input__error-bottom {
        bottom: auto;
        top: rem-calc(40);
    }

    .input-label-bottom {
        display: block;
        bottom: auto;
        right: 0;
        margin-top: rem-calc(10);
    }
}