.dashboard-view-comp {
    th {
        background-color: $aco-white;
        border: 1px solid white;
        font-size: rem-calc(12);
        font-weight: 100 !important;
        height: rem-calc(10);

    }

    .header-status {
        background-image: url('./../../assets/images/icons/question-mark.svg');
        background-repeat: no-repeat;
        background-position: right;
        background-size: 17px;
        cursor: pointer;
    }

    .header-status,
    .header-delete,
    .header-edit,
    .item-status {
        text-align: center;
    }

    .delete-project {
        background-image: url('./../../assets/images/icons/delete.svg');
        background-repeat: no-repeat;
        background-position: center rem-calc(11);
        cursor: pointer;

        &.disabled {
            opacity: .5;
        }
    }

    .edit-project {
        background-image: url('./../../assets/images/icons/edit.svg');
        background-repeat: no-repeat;
        background-position: center rem-calc(11);
        cursor: pointer;

        &.disabled {
            opacity: .5;
        }
    }

    tbody tr:nth-child(2n) {
        background-color: $aco-white;
        height: rem-calc(50);
        border-radius: rem-calc(100);
        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }
    }

    tbody tr {
        background-color: $aco-gray;
        height: rem-calc(50);
        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }
    }

    tbody {
        border-radius: rem-calc(100);
    }

    .last-change {
        /*text-decoration: underline;*/
        cursor: pointer;
    }

    .item-lastchange {
        width: rem-calc(263);
    }

    .item-name {
        width: rem-calc(587);
        font-weight: 550;
    }

    .item-status {
        width: rem-calc(111);
    }

    //.arrow-down {
    //    width: 0;
    //    height: 0;
    //    border-left: 8px solid transparent;
    //    border-right: 8px solid transparent;
    //    border-top: 8px solid $aco-darker-gray;
    //    margin-top: rem-calc(-11);
    //    margin-left: rem-calc(104);
    //}

    /* TODO: display this as soon as table is sortable */
    .arrow-up {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        margin-top: rem-calc(-11);
        margin-left: rem-calc(104);
        border-bottom: 8px solid $aco-darker-gray;
    }

    .projects-border {
        border: 1px solid $aco-gray;
        border-radius: rem-calc(10);
        overflow: hidden;
        max-height: rem-calc(240);
        overflow-y: scroll;
        display: contents;
    }

    .button--newproject {
        height: rem-calc(52) !important;
        width: rem-calc(181) !important;
        min-width: rem-calc(110) !important;
        background-color: $aco-red;
        color: $white;
        margin-top: rem-calc(20);

        &:active {
            box-shadow: 1px 1px 3px 0 rgba($black, .4) inset;
        }
    }
}