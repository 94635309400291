.input {
    position: relative;
    display: block;
    margin-bottom: rem-calc(30);
    transition: opacity .2s ease-in-out;

    &.disabled {
        opacity: .5;
        pointer-events: none;
    }

    &.error {
        color: $error;

        .input__control {
            > input,
            > textarea {
                border-color: $error;
                color: $error;
            }

            [class*='input__error-'] {
                display: block;
            }
        }
    }

    > span + .input__control {
        margin-top: rem-calc(12);
    }
}

.input__control {
    position: relative;
    display: block;

    > input,
    > textarea {
        width: 100%;
    }
}

[class*='input__error-'] {
    display: none;
    position: absolute;
    right: 0;
    color: $error;
    line-height: 1;
    font-size: rem-calc(12);
}

.input__error-bottom {
    bottom: rem-calc(-18);
}

.input__error-right {
    top: 50%;
    transform: translate(110%, -50%);
}

[class*='input--unit-'] {
    .input__control {
        > input {
            padding-right: rem-calc(60);
        }

        &::after {
            content: '';
            position: absolute;
            display: block;
            width: rem-calc(60);
            top: 50%;
            right: 0;
            font-size: rem-calc(14);
            text-align: center;
            transform: translateY(-50%);
        }
    }
}

.input--unit-degree {
    .input__control {
        &::after {
            content: '\00b0';
        }
    }
}

.input--unit-permil {
    .input__control {
        &::after {
            content: '\2030';
        }
    }
}

.input--placeholder-right {
    .input__control > input {
        &::placeholder {
            text-align: right;
        }
    }
}

// Markup is slightly different here (no .input__control wrapper)
.input--small {

    > input {
        padding-top: rem-calc(6);
        padding-bottom: rem-calc(6);
        width: rem-calc(104);
        margin-right: rem-calc(14);
    }

    &.error {
        > input {
            border-color: $error;
            color: $error;
        }

        [class*='input__error-'] {
            display: block;
        }
    }
}

.input--large {
    margin-top: rem-calc(12);
    width: 100%;

    > input {
        padding-top: rem-calc(6);
        padding-bottom: rem-calc(6);
        width: 100%;
    }

    &.error {
        > input {
            border-color: $error;
            color: $error;
        }

        [class*='input__error-'] {
            display: block;
        }
    }
}