.projects-data-comp {

    .button--object-back {
        height: rem-calc(52) !important;
        width: rem-calc(52) !important;
        min-width: rem-calc(52) !important;
        bottom: rem-calc(35);
        background-image: url('./../../assets/images/icons/back.svg');
        background-repeat: no-repeat;
        background-position: rem-calc(0) rem-calc(0);

        &:active {
            box-shadow: 1px 1px 3px 0 rgba($black, .4) inset;
        }
    }
}

.close-mail-text {
    margin-top: rem-calc(130);
}

.button--save-schacht-config {
    height: rem-calc(52) !important;
    width: rem-calc(214) !important;
    min-width: rem-calc(110) !important;
    background-color: $aco-red;
    color: $white;
    position: absolute;
    bottom: rem-calc(35);

    &:active {
        box-shadow: 1px 1px 3px 0 rgba($black, .4) inset;
    }
}

.select-project-list-modal {
    padding-bottom: rem-calc(94);
}

.button--existing-project {
    height: rem-calc(52) !important;
    width: rem-calc(327) !important;
    min-width: rem-calc(110) !important;
    background-color: $aco-red;
    color: $white;

    &:active {
        box-shadow: 1px 1px 3px 0 rgba($black, .4) inset;
    }
}

.button--new-project {
    height: rem-calc(52) !important;
    width: rem-calc(184) !important;
    min-width: rem-calc(110) !important;
    background-color: $aco-red;
    color: $white;

    &:active {
        box-shadow: 1px 1px 3px 0 rgba($black, .4) inset;
    }
}

.button--choose-project {
    height: rem-calc(52) !important;
    width: rem-calc(184) !important;
    min-width: rem-calc(110) !important;
    background-color: $aco-red;
    color: $white;
    position: absolute;
    bottom: rem-calc(35);

    &:active {
        box-shadow: 1px 1px 3px 0 rgba($black, .4) inset;
    }
}

.project-list-modal {
    .modal__content {
        height: rem-calc(548) !important;
    }

    .projects-border {
        margin-top: rem-calc(-30);
    }
}

.project-list-modal--hide {
    .project-name-error {
        color: $aco-red;
        width: rem-calc(350);
    }
}