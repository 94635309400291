.app-footer {
    padding-top: rem-calc(24);
    height: rem-calc(50);
}

.footer-items {
    @extend %list-reset;
    opacity: .7;

    > li {
        float: left;
        margin-right: rem-calc(50);
    }

    li:last-child {
        float: right;
    }

    a {
        color: inherit;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}