.are-you-sure-modal {
  .modal__content {
    position: relative;
    height: 300px;

    .cancel-button {
      position: absolute;
      bottom: 20px;
      right: 220px;
    }

    .delete-button {
      position: absolute;
      bottom: 20px;
      right: 20px;
      color: white;
      background-color: $aco-red;
    }
  }
}