.comp-optional-information {
    .price,
    .weight {
        @extend %h1-base;
    }

    .price-caption {
        @extend %h2-base;
    }

    .price-caption-inflation {
        font-size: rem-calc(24);
        font-weight: $global-weight-bold;
        color: $black;
        line-height: 1.35;
    }

    a.titleBlockButton  {
        margin-bottom: rem-calc(25);
    }
}