@use 'sass:math';

.product-card {
    display: block;
    position: relative;

    > input {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        width: 1px;
        height: 1px;
    }

    &.is-active {
        .product-card__inner {
            border-color: $aco-red;

            &::before {
                background-color: $aco-red;
            }

            &::after {
                opacity: 1;
            }
        }
    }

    &::before {
        display: block;
        content: '';
        width: 100%;
        padding-top: math.div(1, 1) * 100%;
    }

    &__title {
        text-align: center;
        margin-top: rem-calc(10px);
        color: $aco-red;
        font-weight: 600;
    }
}

.product-card__inner {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $aco-gray;
    border: 2px solid rgba($aco-dark-gray, .5);
    border-radius: 10px;
    cursor: pointer;
    transition: border .1s ease-in-out;

    // active state badge
    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: rem-calc(34);
        height: rem-calc(34);
        border-radius: 100%;
        background-color: transparent;
        transform: translate(50%, -50%);
        transition: background .1s ease-in-out;
    }

    &::after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        width: rem-calc(16);
        height: rem-calc(8);
        border-bottom: 2px solid $white;
        border-left: 2px solid $white;
        background: none;
        opacity: 0;
        transform: translate(50%, -75%) rotate(-50deg);
        transition: opacity .1s ease-in-out;
    }
}

.product-card__icon {
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    flex-grow: 1;
    height: 200px;

    &.komplettschacht {
        background-image: url('./../../assets/images/intro_ks.png');
    }

    &.schlammsammler {
        background-image: url('./../../assets/images/intro_ss.png');
    }
}

.product-card--caption {
    display: block;
    flex-shrink: 1;
    padding: 10% rem-calc(8);
    font-weight: bold;
    text-align: center;
    font-size: rem-calc(14);
    line-height: 1.1;
}