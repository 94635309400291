.projects-table-comp {
    th {
        background-color: $aco-white;
        border: 1px solid white;
        font-size: rem-calc(12);
        font-weight: 100 !important;
        height: rem-calc(10);
        text-align: left;
    }

    td {
        font-size: rem-calc(14);
    }

    .radio {
        float: right !important;
        height: rem-calc(0);
    }

    .header-status, .header-delete, .header-edit, .item-status {
        text-align: center;
    }

    .empty-config {
        font-size: rem-calc(18);
        color: $aco-darker-gray;
        text-align: center;
        width: rem-calc(1200);
    }

    .header-status {
        background-image: url('./../../assets/images/icons/question-mark.svg');
        background-repeat: no-repeat;
        background-position: right;
        background-size: 17px;
        cursor: pointer;
    }

    .delete-project, .edit-project {
        background-repeat: no-repeat;
        background-position: center rem-calc(11);
        cursor: pointer;
    }

    .delete-project {
        background-image: url('./../../assets/images/icons/delete.svg');

        &.disabled {
            opacity: .5;
        }
    }

    .edit-project {
        background-image: url('./../../assets/images/icons/edit.svg');

        &.disabled {
            opacity: .5;
        }
    }

    tbody tr:nth-child(2n) {
        background-color: $aco-white;
        height: rem-calc(50);
        border-radius: rem-calc(100);
        display:table;
        width:100%;
        table-layout:fixed;
        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }
    }

    tbody tr {
        background-color: $aco-gray;
        height: rem-calc(50);
        display:table;
        width:100%;
        table-layout:fixed;
        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }
    }

    tbody {
        border-radius: rem-calc(100);
        display: block;
        height: 240px;
        overflow: auto;
    }

    thead {
        display:table;

        table-layout:fixed;
        width: calc( 100% - 1em )/* scrollbar is average 1em/16px width, remove it from thead width */
    }

    .last-change {
        /*text-decoration: underline;*/
        cursor: pointer;
    }

    .item-name {
        font-weight: 550;
        background-image: url('./../../assets/images/icons/information-button.svg');
        background-repeat: no-repeat;
        background-position: 99%;
        width: rem-calc(400);

        & > .item-name-text {
            max-width: 90%;
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
        }
    }

    //.arrow-down {
    //    width: 0;
    //    height: 0;
    //    border-left: 8px solid transparent;
    //    border-right: 8px solid transparent;
    //    border-top: 8px solid $aco-darker-gray;
    //    margin-top: rem-calc(-11);
    //    margin-left: rem-calc(104);
    //}

    /* TODO: display this as soon as table is sortable */
    .arrow-up {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        margin-top: rem-calc(-11);
        margin-left: rem-calc(104);
        border-bottom: 8px solid $aco-darker-gray;
    }

    .projects-border {
        border: 1px solid $aco-gray;
        border-radius: rem-calc(10);
    }

    .button--objectinfos {
        margin-right: rem-calc(15);
    }

    .button--addproject {
        height: rem-calc(52) !important;
        width: rem-calc(222) !important;
        min-width: rem-calc(110) !important;
        background-color: $aco-red;
        color: $white;
        margin-top: rem-calc(20);

        &:active {
            box-shadow: 1px 1px 3px 0 rgba($black, .4) inset;
        }
    }

    .schacht {
        width: rem-calc(400);
    }
}

.protocol-modal {
    font-size: rem-calc(12);
    color: $aco-gray;
    .modal__content {
        background-color: $aco-darker-gray !important;
    }
    .close {
        background-image: url('./../../assets/images/icons/cross-white.svg');
    }
}

.product-container {
    height: rem-calc(330);
    width: 100%;
    overflow-y: auto;
}

.legend-modal {
    font-size: rem-calc(12);
    color: $aco-gray;

    .modal__content {
        background-color: $aco-darker-gray !important;

        .close {
            background-image: url('./../../assets/images/icons/cross-white.svg');
        }

        .legend-container {
            height: rem-calc(330);
            width: 100%;
            overflow-y: auto;

            .title {
                font-size: rem-calc(16);
            }

            li {
                margin: 0 0 rem-calc(10);
            }
        }
    }
}

.product-title {
    text-transform: capitalize;
}

.pipe-material {
    text-transform: uppercase;
}