.app-header {
    height: rem-calc(70);

    .logo {
        vertical-align: baseline;
    }
}

.beta-label {
    float: right;
    margin-top: 2rem;
    margin-left: rem-calc(15);
    cursor: pointer;

    button[type=submit] {
        color: $aco-dark-gray;
        transition: color .2s ease-in-out;

        &:hover {
            color: rgba($aco-dark-gray, .75);
        }
    }
}

.logo {
    width: rem-calc(70);
}

.app-title {
    display: inline-block;
    margin: 0;
    font-size: rem-calc(20);
    font-weight: normal;
    line-height: 1.4;
}