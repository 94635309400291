// sass-lint:disable no-important

.scroll-y {
    overflow-y: auto;
}

.relative {
    position: relative;
}

.bg-gray {
    background-color: $aco-gray;
}

.full-width {
    width: 100% !important;
}

.cf {
    &::after {
        content: '';
        display: block;
        clear: both;
    }
}

.right {
    float: right;
}

.space-1 {
    margin-bottom: 1rem;
}

.space-2 {
    margin-bottom: 2rem;
}

.space-3 {
    margin-bottom: 3rem;
}

.margin-top--2 {
    margin-top: -2rem;
}

.margin-top--4 {
    margin-top: -4rem;
}

.margin-top--6 {
    margin-top: -6rem;
}

.margin-top--8 {
    margin-top: -8rem;
}

.margin-top--10 {
    margin-top: -8rem;
}

.margin-left-20 {
    margin-left: rem-calc(20);
}