.project-info-comp {
  .button--save-object-edit {
    height: rem-calc(52) !important;
    width: rem-calc(277) !important;
    min-width: rem-calc(110) !important;
    background-color: $aco-red;
    color: $white;
    bottom: rem-calc(35);

    &:active {
      box-shadow: 1px 1px 3px 0 rgba($black, .4) inset;
    }
  }

  .button--object-back {
    height: rem-calc(52) !important;
    width: rem-calc(52) !important;
    min-width: rem-calc(52) !important;
    margin-right: rem-calc(20);
    background-image: url('./../../assets/images/icons/back.svg');
    background-repeat: no-repeat;
    background-position: rem-calc(0) rem-calc(0);

    &:active {
      box-shadow: 1px 1px 3px 0 rgba($black, .4) inset;
    }
  }

  .error {
    color: $aco-red;
  }

  .customer-number {
    width: 81%;
  }

  .main-content-footer {

    .container {
      height: 100%;
      align-items: center;
      display: flex;
    }
  }
}