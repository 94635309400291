.login-box-cont {
    .error {
        border-color: $aco-red !important;
    }
}

.login-modal {
    .button--login {
        height: rem-calc(52) !important;
        width: rem-calc(110) !important;
        min-width: rem-calc(110) !important;
        background-color: $aco-red;
        color: $white;

        &:active {
            box-shadow: 1px 1px 3px 0 rgba($black, .4) inset;
        }
    }

    .email-error {
        color: $aco-red;
    }

    .error {
        border-color: $aco-red !important;
    }

    .login-link, .password-reset-link {
        color: $aco-red;
    }
}