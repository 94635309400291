.checkbox {
    display: block;
    position: relative;
    margin-bottom: rem-calc(20);
    width: 100%;

    > input {
        position: absolute;
        opacity: 0;
    }

    label {
        cursor: pointer;

        &::before {
            content: '';
            display: inline-block;
            margin-right: rem-calc(16);
            width: rem-calc(34);
            height: rem-calc(34);
            border: 1px solid $aco-gray;
            border-radius: 10px;
            vertical-align: middle;
            transition: border-color .2s ease-in-out, background .2s ease-in-out;
        }

        &::after {
            content: '';
            position: absolute;
            display: block;
            top: rem-calc(11);
            left: rem-calc(9);
            width: rem-calc(16);
            height: rem-calc(8);
            border-bottom: 2px solid $white;
            border-left: 2px solid $white;
            background: none;
            transform: rotate(-50deg);
        }
    }

    > input:checked + label {
        &::before {
            border-color: $aco-red;
            background-color: $aco-red;
        }
    }

    > input:disabled + label {
        cursor: default;

        &::before {
            opacity: .5;
        }
    }

    &.disabled {
        opacity: .5;

        > label {
            cursor: default;
        }
    }
}