.compDebugOutput {
    padding: 1.5rem;
    margin: 1.5rem 0;

    div {
        width: 40%;
        overflow: auto;
        font-family: monospace;
        font-size: 19px;
        background-color: #0a0a0a;
        color: whitesmoke;

        opacity: 0.5;

        padding: 0.8rem;

        span {
            display: block;
            float: left;
            width: 47%
        }

        span:last-child {
            text-align: right;
            color: lightblue;
        }
    }
}