.radio {
    position: relative;
    height: rem-calc(57);
    display: flex;
    //justify-content: center;
    align-items: center;
    padding: rem-calc(20) 0;
    text-decoration: none;

    > input {
        position: absolute;
        opacity: 0;
    }

    > label {
        position: relative;
        display: block;
        padding-left: rem-calc(48);
        max-width: 90%;
        line-height: 1.3;
        transition: opacity .2s ease-in-out;
        cursor: pointer;

        // Checkbox
        &::before {
            content: '';
            margin-right: rem-calc(16);
            width: rem-calc(34);
            height: rem-calc(34);
            border: 1px solid #aaa;
            border-radius: 100%;
            vertical-align: middle;
            transition: border-color .2s ease-in-out, box-shadow .2s ease-in-out;
            background-color: $aco-red;
            box-shadow: 0 0 0 20px $white inset;
            float: left;

            position: absolute;
            padding: rem-calc(8) 0;
            display: block;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            line-height: 1;
            text-align: center;
        }
    }

    > input:checked + label {
        &::before {
            border-color: $aco-red;
            box-shadow: 0 0 0 8px $white inset;
        }
    }

    &.disabled {
        opacity: .5;

        > label {
            cursor: default;
        }
    }
}

.focused {
    outline: 2px solid #005fcc;
    outline-offset: 2px;
}