@use 'sass:math';
@import 'node_modules/foundation-sites/scss/util/util';

.angle-view-circle {
    position: absolute;
    right: 0;
    top: 266px;
    margin: 0 auto 0 auto;
    width: rem-calc(369);

    &-inlet {
        left: 50%;
    }

    &-outfall {
        left: 0;
    }

    &-values {
        width: 500px;
        top: 200px;

        &-inlet {
            left: 50%;
        }

        &-outfall {
            left: 0;
        }
    }

    &-needle {

        &-inlet {
            left: 50%;
        }

        &-outfall {
            left: 0;
        }

        top: 450px;
        background-color: #ff4c53;
        height: 200px;
        border-radius: 70px;
        transform-origin: center top;
    }

    &-static {
        top: 450px;
        background-color: #ff4c53;
        height: 200px;
        border-radius: 70px;

        &-inlet {
            left: 50%;
        }

        &-outfall {
            left: 0;
        }
    }

    &-static-outfall-arrow {
        position: absolute;
        bottom: 20px;
    }
}

.angle-view-inlet-arrow {
    position: absolute;
    bottom: 20px;
    transform: rotate(180deg);
}

.angle-view-title {
    margin-top: 135px;
}

.angle-view-dot {
    background-color: $aco-light-red;
    position: absolute;
    top: 425px;
    left: 73.1%;
    right: 0;
    height: 50px;
    width: 50px;
    border-radius: 30px;
}

.angle-view-wrapper, .angle-view-wrapper-outfall {
    position: sticky;
    top: -27vh;
}

.angle-view-wrapper-cover-entrance {

    top: -5vh;
    $big_circle_size: 443;

    .angle-view-circle {
        &-cover-entrance {
            left: 0;
            top: rem-calc(59);
            width: rem-calc(325);
        }
    }

    .angle-view-circle-values {
        &-cover-entrance {
            top: 0;
            left: 0;
            width: rem-calc($big_circle_size);
        }
    }

    .angle-view-dot {

        $dot_size: 25;

        left: 0;
        margin: 0 auto;
        height: rem-calc($dot_size);
        width: rem-calc($dot_size);
        top: rem-calc(math.div(($big_circle_size - $dot_size), 2));

        &.entrance-at-0 {
            display: none;
        }

        // this is 0 degree
        &.entrance-at-1 {
            top: rem-calc(math.div(($big_circle_size - $dot_size), 1.4));
        }

        &.entrance-at-90 {
            left: rem-calc(math.div(($big_circle_size - $dot_size), 2.4));
        }

        &.entrance-at-180 {
            top: rem-calc(math.div(($big_circle_size - $dot_size), 3.4));
        }

        &.entrance-at-270 {
            right: rem-calc(math.div(($big_circle_size - $dot_size), 2.4));
        }
    }
}

.angle-view-wrapper-inlet {
    transform-origin: 750px;
}

@media (max-width: 1250px) {
    .angle-view-dot {
        left: 72.75%;
    }
}

@media (max-width: 1090px) {
    .angle-view-circle-values-inlet {
        left: 448px;
    }
}

@media (max-width: 1150px) {
    .angle-view-circle-values-outfall {
        min-width: 500px;
    }
    .angle-view-circle-outfall, .angle-view-circle-needle-outfall, .angle-view-circle-static-outfall {
        right: -65px;
    }
}

@media (min-height: 701px) and (max-height: 750px) {
    .angle-view-wrapper {
        transform: scale(0.75);
    }
}

@media (min-height: 651px) and (max-height: 700px) {
    .angle-view-wrapper {
        transform: scale(0.65);
    }
}

@media (min-height: 601px) and (max-height: 650px) {
    .angle-view-wrapper {
        transform: scale(0.55);
    }
}

@media (min-height: 551px) and (max-height: 600px) {
    .angle-view-wrapper {
        transform: scale(0.5);
    }
}

@media (min-height: 510px) and (max-height: 550px) {
    .angle-view-wrapper {
        transform: scale(0.45);
    }
}

@media (max-height: 510px) {
    .angle-view-wrapper, .angle-view-wrapper-outfall {
        display: none;
    }
}

@media (max-width: 640px) {
    .angle-view-wrapper, .angle-view-wrapper-outfall {
        display: none;
    }
}
