.button-bar {
    @extend %list-reset;

    display: flex;
    height: 100%;
    min-height: rem-calc(69);
    max-height: rem-calc(90);
    align-items: center;

    &::after {
        content: '';
        min-height: inherit;
        font-size: 0;
    }
}

.button-bar__item {
    display: block;
    margin-right: rem-calc(20);
}