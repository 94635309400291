.password-request-modal {
  .button--password-request {
    height: 100% !important;
    width: 100% !important;
    min-width: rem-calc(110) !important;
    background-color: $aco-red;
    color: $white;

    &:active {
      box-shadow: 1px 1px 3px 0 rgba($black, .4) inset;
    }
  }

  .close {
    margin-top: rem-calc(17px);
  }

  a {
    color: $aco-red;
  }

  a {
    font-size: 12px !important;
  }

  .password-request {
    margin-top: rem-calc(-18);
    width: 800px;
  }

  .modal__content {
    min-height: rem-calc(400);
    height: auto;
  }

  .email-error {
    color: $aco-red;
    position: absolute;
    margin-top: rem-calc(-30);
  }
}