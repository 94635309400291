.app-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    border-radius: 10px;
    overflow: hidden;
}

.nav-header {
    width: 100%;
}

.main-content {
    flex: 1;
    padding: rem-calc(72) rem-calc(32) rem-calc(20);
    margin-bottom: rem-calc(70);
}

.main-content-footer {
    position: absolute;
    padding: 0 rem-calc(32);
    bottom: 0;
    left: 0;
    width: 100%;
    height: rem-calc(70);
    border-top: 1px solid $aco-gray;
}

