// Colors (from styleguide)
$aco-gray: rgb(224, 226, 228);
$aco-optional-grey: rgba(224, 226, 228, .7);
$aco-dark-gray: rgb(129, 133, 137);
$aco-darker-gray: #5a6169;
$aco-white: #fff;
$aco-red: rgb(255, 76, 83);
$aco-light-red: #ffa5a9;
$aco-dark-red: #b33844;
$aco-blue: rgb(116, 166, 227);
$aco-light-blue: rgb(148, 205, 221);
$aco-dark-blue: rgb(81, 132, 195);
$error: rgb(237, 28, 36);

//// globals
//$global-margin: 1rem;
//$global-radius: 10px;
//
//$foundation-palette: (
//    primary: #ff4c53,
//    secondary: #74a6e3,
//    success: #28ae88,
//    warning: #f39022,
//    alert: #ed1c24,
//    tertiary: #eae9ee
//);
//
$configurator-palette: (
    body: #eae9ee,
    content-background: rgb(255, 255, 255),
    border-color: #e0e2e4,
    card-background: #f0eff4,
    card-border-color: #c7c7d3,
    heading-color: #818589
);
//
//
//
//// body settings
//$body-background: map-get($configurator-palette, 'body');
//$body-font-color: rgba(0, 0, 0, .7);
//$body-font-family: Verdana;
//
//
//// grid settings
//$grid-container: 1600px; //enlarge max-width of the grid-container
//$grid-container-padding: 57px;
//$grid-padding-gutters: 46px;
//
//
////button-settings
//$button-font-color: #fff !default;
//
//// Default alternative font color for button.
//$button-font-color-alt: #000 !default;
//
//$button-radius: 10px;
//$button-padding: .85em 2.5em;
//$button-margin: 0 1.5rem $global-margin 0;
//
//$global-color-pick-contrast-tolerance: 3; // increase tolerance because of color scheme. (design issue?)
//
//$buttongroup-spacing: 1.25rem;
//
//
//// form settings
//
//$form-label-color: map-get($configurator-palette, 'heading-color');
//$form-label-line-height: 2.93;
//
//$input-background-disabled: rgb(255, 255, 255);
//$input-color: $form-label-color;
$input-color-disabled: rgba(129, 133, 137, .5); //non-foundation variable
$input-border-color-disabled: rgba(199, 199, 211, .5); //non-foundation variable
//
//
//// reveal config
//
//$reveal-padding: 4.75rem 9.5rem 2.8125rem 4.75rem;
//$reveal-width: 897px;