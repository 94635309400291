.progress-bar {
    @extend %list-reset;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $aco-gray;
}

.progress-bar__item {
    flex-grow: 1;
    flex-basis: 0;
    counter-increment: step-counter;

    .progress-bar__link {
        border-right: 1px solid $aco-gray;
    }

    &:last-child {
        .progress-bar__link {
            border-right: 0;
        }
    }
}

.progress-bar__link {
    position: relative;
    height: rem-calc(64);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: rem-calc(20) 0;
    text-decoration: none;

    > span {
        position: relative;
        display: block;
        padding-left: rem-calc(38);
        max-width: 90%;
        line-height: 1.3;
        transition: opacity .2s ease-in-out;

        // Step badge
        &::before {
            content: counter(step-counter);
            position: absolute;
            padding: rem-calc(8) 0;
            display: block;
            top: 50%;
            left: 0;
            width: rem-calc(30);
            height: rem-calc(30);
            transform: translateY(-50%);
            border-radius: 100%;
            line-height: 1;
            background-color: $aco-gray;
            text-align: center;
            transition: background .2s ease-in-out, color .2s ease-in-out;
        }
    }

    // Progress indicator
    &::before {
        content: '';
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        width: 0;
        height: rem-calc(4);
        background-color: $aco-red;
        transition: width .2s ease-in-out;
    }

    &.is-active {
        > span {
            color: $aco-red;

            &::before {
                background-color: $aco-red;
                color: $white;
            }
        }

        &[data-progress='1/2'] {
            &::before {
                width: 50%;
            }
        }

        &[data-progress='1/3'] {
            &::before {
                width: 33.33%;
            }
        }

        &[data-progress='2/3'] {
            &::before {
                width: 66.66%;
            }
        }
    }

    &.is-completed {
        > span {
            color: $aco-red;
            opacity: .5;

            &::before {
                background-color: $aco-red;
                color: $white;
            }
        }

        &::before {
            width: calc(100% + 1px); // Add 1px because of border, so progress-bar is seamless.
        }
    }
}
