@import '../../scss/abstracts/variables';

.protocol {
    position: absolute;
    top: 100px;
    right: 0;

    .protocol-inner {
        position: relative;
    }

    .protocol-box-toggle {
        transition: 0.5s;

        cursor: pointer;
        border-radius: 10px 0 0 10px;
        width: 39px;
        height: 108px;
        background-color: $aco-dark-gray;
        position: absolute;
        top: 96px;
        right: 303px;
        -webkit-box-shadow: -2px 2px 21px -2px rgba(0, 0, 0, 0.34);
        -moz-box-shadow: -2px 2px 21px -2px rgba(0, 0, 0, 0.34);
        box-shadow: -2px 2px 21px -2px rgba(0, 0, 0, 0.34);

        .protocol-title {
            color: $aco-white;
            margin-top: 57px;
            margin-right: 12px;
            transform: rotate(270deg);
            font-size: 12px;
        }

        .protocol-title-arrow {
            height: 33px;
            width: 15px;

            position: absolute;
            left: 24px; // 39px - 15px;
            top: 35px;

            border-bottom-left-radius: 80px;
            border-top-left-radius: 80px;
            border-top-right-radius: 0;

            background-color: $aco-darker-gray;
            display: inline-block;

            &:before {
                content: ' ';

                border: 2px solid $aco-white;
                border-width: 2px 0 0 2px;
                display: inline-block;

                height: 10px;
                width: 10px;
                transform: rotate(130deg);

                position: relative;
                left: 3px;
                top: 11px;

                transition: 0.2s;
                transition-timing-function: ease-in;
            }
        }
    }

    .protocol-content-box {
        border-radius: 10px 0 0 10px;
        width: 303px;
        height: 300px;
        float: right;
        background-color: $aco-darker-gray;
        -webkit-box-shadow: 18px 17px 32px -13px rgba(156, 156, 156, 0.92);
        -moz-box-shadow: 18px 17px 32px -13px rgba(156, 156, 156, 0.92);
        box-shadow: 18px 17px 32px -13px rgba(156, 156, 156, 0.92);
        transition: 0.5s;

        .protocol-content {
            font-size: 12px;
            color: $aco-white;
            width: 303px;
            padding-left: 20px;
            padding-top: 23px;
            padding-bottom: 20px;

            overflow: auto;
            max-height: 300px;
            max-width: 303px;
        }

        .protocol-text {
            b {
                display: block;
                margin-bottom: 10px;
                margin-top: 10px;
            }

            .title {
                margin-top: 0;
            }

            p {
                margin: 0 0 5px;
                font-size: 12px;
            }
        }
    }


    &.close {
        max-height: 0;
        max-width: 39px;

        .protocol-box-toggle {
            .protocol-title-arrow {
                &:before {
                    transform: rotate(-45deg);
                    left: 7px;
                }
            }

            right: 0;
        }

        .protocol-content-box {
            width: 0;
            overflow: hidden;

            .protocol-content {
                min-width: 0;
            }
        }
    }
}