.product-list {
    @extend %list-reset;

    display: flex;
    flex-direction: row;
    padding-right: rem-calc(80);

    li {
        display: block;
        margin-right: rem-calc(30);
        width: rem-calc(240);

        &:last-child {
            margin-right: 0;
        }
        &.product-image {
            width: 100%;
        }
    }
}