.register-box-cont {
}

.register-modal {
    .button--register {
        height: rem-calc(52) !important;
        width: rem-calc(148) !important;
        min-width: rem-calc(110) !important;
        background-color: $aco-red;
        color: $white;

        &:active {
            box-shadow: 1px 1px 3px 0 rgba($black, .4) inset;
        }
    }

    .agb-label {
        display: -webkit-box;
    }

    .agb-text {
        margin-top: rem-calc(-36);
        margin-left: rem-calc(48);
        width: rem-calc(315);
        font-size: rem-calc(12);
    }

    .close {
        margin-top: rem-calc(17px);
    }

    .checkbox-group {
        margin-top: rem-calc(9);
    }

    .agb-link {
        font-size: 12px !important;
        display: inline-flex;
        width: rem-calc(460);
    }

    a {
        color: $aco-red;
    }

    a {
        font-size: 12px !important;
    }

    .register {
        margin-top: rem-calc(-18);
        width: 800px;
    }

    .accept-agb {
        display: inline-flex;
        margin-top: rem-calc(6);
    }

    .modal__content {
        min-height: rem-calc(400);
        height: auto;
    }

    .email-error {
        color: $aco-red;
        position: absolute;
        margin-top: rem-calc(-30);
    }
}