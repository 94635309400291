.modal {
    .modal__content {
        height: 400px;
        width: 800px;
        max-width: 800px;
        margin-top: rem-calc(180);
        padding: rem-calc(50) rem-calc(45);
        border-radius: 10px;
        //-webkit-box-shadow: 10px 10px 78px -7px rgba(0, 0, 0, .75);
        //-moz-box-shadow: 10px 10px 78px -7px rgba(0, 0, 0, .75);
        box-shadow: 10px 10px 78px -7px rgba(0, 0, 0, .75);
    }

    .close {
        height: rem-calc(30);
        width: rem-calc(30);
        background-image: url('./../../assets/images/icons/cross.svg');
        background-repeat: no-repeat;

        &:hover {
            opacity: .7;
        }
    }

    .close-container {
        margin-left: rem-calc(711);
        margin-top: rem-calc(-34);
        position: absolute;
        cursor: pointer;
    }
}