.comp-floortype {
    .radio-group {
        .radio {
            height: rem-calc(55);
        }
        h3 {
            margin-top: rem-calc(30);
            margin-bottom: rem-calc(10);
        }
    }

}