input,
input[type='text'],
input[type='email'],
input[type='date'],
input[type='tel'],
input[type='number'],
select,
textarea {
    @extend %paragraph-base;

    padding: rem-calc(16) rem-calc(20);
    line-height: 1.3;
    color: $body-font-color;
    background: none;
    border: 2px solid $aco-gray;
    border-radius: 10px;
    text-overflow: ellipsis;

    &::placeholder {
        color: rgba($body-font-color, .6);
    }
}

// sass-lint:disable no-vendor-prefixes
input[type='number'] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }
}
// sass-lint:enable no-vendor-prefixes

.input--optional {
    input,
    select,
    textarea {
        border-color: $aco-optional-grey;
    }
}

.input--required {
    .label--content {
        &::after {
            content: '\2A';
            padding: 0 0 0 rem-calc(7);
            color: $aco-red;
        }

        &.hide-sign {
            &::after {
                display: none;
            }
        }
    }
}
