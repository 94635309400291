.dropdown {
    position: relative;
    display: block;
    margin-bottom: rem-calc(30);
    transition: opacity .2s ease-in-out;

    select {
        appearance: none;

        // sass-lint:disable no-vendor-prefixes
        &::-ms-expand {
            display: none;
        }
        // sass-lint:enable no-vendor-prefixes
    }

    &.disabled {
        opacity: .5;
        pointer-events: none;
    }

    &.error {
        color: $error;

        .dropdown__control {
            > select {
                border-color: $error;
                color: $error;
            }

            [class*='dropdown__error-'] {
                display: block;
            }
        }
    }

    > span + .dropdown__control {
        margin-top: rem-calc(12);
    }
}

.dropdown__control {
    position: relative;
    display: block;

    &::before {
        content: '';
        position: absolute;
        display: block;
        width: rem-calc(60);
        height: calc(100% - 4px);
        top: 2px;
        right: 2px;
        background-color: rgba($aco-gray, .75);
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
        border-left: 2px solid $aco-gray;
        pointer-events: none;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: rem-calc(27);
        width: 11px;
        height: 11px;
        background-image: url('./../../assets/images/angle-down-gray.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        transform: translateY(-50%);
        pointer-events: none;
    }
}

[class*='dropdown__error-'] {
    display: none;
    position: absolute;
    right: 0;
    color: $error;
    line-height: 1;
    font-size: rem-calc(12);
}

.dropdown__error-bottom {
    bottom: rem-calc(-18);
}

.dropdown__error-right {
    top: 50%;
    transform: translate(110%, -50%);
}