.info-text {
    position: relative;
    max-width: rem-calc(480);
    font-size: rem-calc(12);
    border-radius: 10px;
    background-color: $aco-light-blue;
    padding: rem-calc(20) rem-calc(20) rem-calc(10) rem-calc(50);
    color: white;

    p {
        font-size: inherit;
        margin: inherit;
    }

    &::before {
        content: 'i';
        position: absolute;
        top: 10px;
        left: 10px;
        text-align: center;
        background-color: $aco-light-blue;
        color: $white;
        width: rem-calc(30);
        height: rem-calc(30);
        border-radius: 100%;
        font-weight: bold;
        font-style: italic;
        font-family: serif;
        font-size: rem-calc(26);
        line-height: rem-calc(30);
        vertical-align: middle;
    }

    tbody, tbody tr:nth-child(2n) {
        border: none !important;
        background-color: $aco-light-blue !important;
    }

    .table-info-text {
        font-size: rem-calc(12);
        color: $aco-white;
    }
}