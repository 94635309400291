%list-reset {
    margin: 0;
    padding: 0;
    list-style: none;
}

%paragraph-base {
    font-size: rem-calc(14);
    font-weight: normal;
    line-height: 1.5;
}

%h1-base {
    font-size: rem-calc(60);
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: -.02em;
}

%h2-base {
    font-size: rem-calc(30);
    font-weight: normal;
    line-height: 1.36;
}

%h3-base {
    font-size: rem-calc(20);
    font-weight: normal;
    line-height: 1.4;
}

%h4-base {
    font-size: rem-calc(18);
    font-weight: bold;
    line-height: 1.5;
}