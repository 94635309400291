body {
    min-width: 1024px;
    min-height: 768px;
}

p,
a,
label,
li,
span {
    @extend %paragraph-base;
}

p {
    margin: 0 0 1rem;

    &.lead {
        @extend %h2-base;
        margin: 0 0 rem-calc(30);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
}

h1 {
    @extend %h1-base;

    margin-bottom: .8em;
}

h2 {
    @extend %h2-base;
    margin-bottom: rem-calc(50);
}

h3 {
    @extend %h3-base;
    margin-bottom: 1em;
}

h4,
h5,
h6 {
    @extend %h4-base;
    margin-bottom: 1rem;
}

a {
    color: $aco-dark-gray;
    transition: color .2s ease-in-out;

    &:hover {
        color: rgba($aco-dark-gray, .75);

    }
}

ul {
    padding-left: 1rem;
}

hr {
    margin: rem-calc(30) 0 rem-calc(40);
    height: 1px;
    background-color: $aco-gray;
    border: 0;
}