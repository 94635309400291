.button {
    display: inline-block;
    padding: rem-calc(16);
    min-width: rem-calc(180);
    border-radius: 10px;
    background-color: $aco-gray;
    font-family: $body-font-family;
    font-weight: bold;
    text-align: center;
    line-height: 1.4;
    color: $body-font-color;
    box-shadow: 0 0 0 0 rgba($black, 0) inset;
    transition: box-shadow .2s ease-in-out;
    cursor: pointer;

    &:disabled {
        opacity: .5;
        cursor: default;
    }

    &:active {
        box-shadow: 1px 1px 3px 0 rgba($black, .2) inset;
    }
}

.button--red {
    background-color: $aco-red;
    color: $aco-white;

    &:active {
        box-shadow: 1px 1px 3px 0 rgba($black, .4) inset;
    }
}

.button--dark-red {
    background-color: $aco-dark-red;
    color: $aco-white;

    &:active {
        box-shadow: 1px 1px 3px 0 rgba($black, .4) inset;
    }
}

.button--blue {
    background-color: $aco-blue;
    color: $aco-white;
}

.button--dark-grey {
    background-color: $aco-dark-gray;
    color: $aco-white;
}

.button-link {
    margin-top: 1rem;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}